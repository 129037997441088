/* App.css */

#logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.content-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
}

@media (max-width: 600px) {
  .content-row {
      grid-template-columns: repeat(1, 1fr);
  }
}

.item {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.item img {
  max-width: 60%;
  height: auto;
  border-radius: 8px;
}

.item h4 {
  margin-top: 10px;
  font-size: 1.2em;
}

.item p {
  font-size: 0.9em;
  margin: 10px 0;
}

.item .button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 0.9em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.item .button:hover {
  background-color: #0056b3;
}

.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
}
