/* Default grid layout for larger screens */
.content-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 tiles per row */
  gap: 20px;
  padding: 20px;
}

/* Grid layout for mobile screens */
@media (max-width: 600px) {
  .content-row {
      grid-template-columns: repeat(1, 1fr); /* 1 tile per row */
  }
}

.item {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.item h4 {
  margin-top: 10px;
  font-size: 1.2em;
}

.item p {
  font-size: 0.9em;
  margin: 10px 0;
}

.item .button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 0.9em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.item .button:hover {
  background-color: #0056b3;
}

#logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust as needed */
}

/* Search Bar */
.input-group {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.input-group input {
  flex: 1;
  max-width: 400px;
  margin-right: 10px;
}

.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px; /* Adjust as needed */
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  transition: all 0.3s;
}

.back-to-top:hover {
  background-color: #0056b3;
  color: #fff;
  text-decoration: none;
}
